.css-6msobk-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: green !important;
  border: 1px solid #1a73e8 !important;
  background-color: black;
}
.css-qtdsus-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: rgb(26, 115, 232) !important;
}
.css-1972oc1-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: green !important;
  border: 1px solid #1a73e8 !important;
  /* color: white !important; */
}
.css-lhgfej:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-1irgnpm-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: green !important;
  color: white !important;
  border: 1px solid #1a73e8 !important;
}
.css-17mnlci-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: green !important;
  color: white !important;
  border: 1px solid #1a73e8 !important;
}

.css-1hc8uz3:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-19vaau7.Mui-completed {
  color: green !important;
}
.css-1epxvkz:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-valp68:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-mpj1yy:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid rgb(26, 115, 232) !important;
}
.css-1hu0klz-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-qtdsus-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: green !important;
}
.css-zyfrhq-MuiStepLabel-label.Mui-completed {
  font-weight: 400 !important;
  color: green !important;
}

.css-zyfrhq-MuiStepLabel-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.01071em;
  display: block;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-top: 0.5rem !important;
  font-weight: 400;
  font-size: 0.75rem;
  color: #fffaf0cc !important;
  text-transform: uppercase;
}
.css-15vpzwh .MuiAutocomplete-tag {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: auto;
  padding: 0.25rem;
  background-color: rgb(26, 115, 232) !important;
  color: black !important;
}
.questionHover:hover {
  background-color: #1a73e8 !important;
  color: white !important;
  border: 1px solid #1a73e8 !important;
}
.css-1dta8ot-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  color: white !important;
  border: 1px solid #1a73e8 !important;
}
.css-xicbw2-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-zsdzi5-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-2a5w94-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-w13ava-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-537krz:hover {
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-d6m3rs:hover {
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-1qj0phy:hover {
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-mms94u:hover {
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-1atua31-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-1ylycbs-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-117w1su-MuiStepIcon-text {
  fill: white !important;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.css-117w1su-MuiStepIcon-text {
  fill: white !important;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.css-3k9red-MuiStepper-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  background: #34aadc !important;
  padding: 1.5rem 0 1rem;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 187, 212, 0.4);
}
.css-zyfrhq-MuiStepLabel-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.01071em;
  display: block;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-top: 0.5rem !important;
  font-weight: 400;
  font-size: 0.75rem;
  color: black !important;
  text-transform: uppercase;
}

.css-zyfrhq-MuiStepLabel-label.Mui-completed {
  font-weight: 600 !important;
  color: black !important;
}
.css-zyfrhq-MuiStepLabel-label.Mui-active {
  font-weight: 600 !important;
  color: black !important;
}

.css-3k9red-MuiStepper-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  background: none !important;
  padding: 1.5rem 0 1rem;
  border-radius: 0.5rem;
  box-shadow: none !important;
}
.css-zyfrhq-MuiStepLabel-label.Mui-active {
  color: red !important;
  font-weight: 500;
}
.css-zyfrhq-MuiStepLabel-label.Mui-completed {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.css-7x8tle-MuiStepConnector-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: absolute;
  top: 12px;
  left: calc(-40% + 20px);
  right: calc(48% + 20px) !important;
  color: black !important;
  background: black !important;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  top: 14%;
  left: -50%;
  right: 50%;
}

.css-1w2v6mf {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: none !important;
  padding: 1.5rem 0px 1rem;
  border-radius: 0.5rem;
  box-shadow: none !important;
}
.jss50 {
  color: black !important;
  border: 0;
  height: 3px;
  margin-left: 1px;
  margin-right: 10px;
  border-radius: 1px;
  background-color: black !important;
}
.jss80 {
  color: black !important;
  border: 0;
  height: 3px;
  margin-left: 1px;
  margin-right: 10px;
  border-radius: 1px;
  background-color: black !important;
}
.css-mofbdh {
  background: #000 !important;
  color: #000 !important;
  flex: 1 1 auto;
  left: -47% !important;
  position: absolute;
  right: 53% !important ;
  top: 14%;
  transition: all 0.2s linear 0s;
}
.css-1otax5l-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  text-align: center;
  margin-top: 16px;
  font-weight: 400;
  color: black;
}
.css-1otax5l-MuiStepLabel-label.Mui-completed {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
}
.css-19vaau7.Mui-active {
  color: #1a73e8 !important;
}
.css-mofbdh {
  flex: 1 1 auto;
  position: absolute;
  color: rgb(159, 201, 255);
  transition: all 200ms linear 0s;
  top: 14%;
  left: -50% !important;
  right: 53% !important;
}
.css-1hngkjo {
  width: 100%;
  color: #000 !important;
  font-weight: 900 !important;
}
