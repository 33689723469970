@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #1e90ff;
  --white: #ffffff;
  --interfont: "Inter", sans-serif;
  --poppinfont: "Poppins", sans-serif;
}

body {
  font-family: var(--interfont);
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.5;
  padding-top: 80px;
  background: white !important;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--poppinfont);
  font-weight: 600;
}

.cor-col{
  color: var(--primary)
}
.interFont {
  font-family: var(--interfont);
}
.poppinsFont {
  font-family: var(--poppinfont);
}
@media screen and (min-width: 992px) {
  h1 {
    font-size: 72px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
}

.circle {
  position: absolute;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #f0f8ff;
  z-index: -1;
}
.circle1 {
  width: 165px;
  height: 165px;
  left: 23%;
  top: -107px;
}
.circle2 {
  width: 374px;
  height: 374px;
  right: 0px;
  top: -204px;
}

.btn {
  height: 45px;
  min-width: 145px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
a.btn {
  line-height: 34px;
}
.btn i {
  margin-left: 10px;
  vertical-align: middle;
}

.navbar-collapse .btn {
  height: 35px;
  line-height: 22px;
  min-width: inherit;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--primary);
}

.scrolled .fixed-top {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.21);
}
@media screen and (min-width: 576px) and (max-width: 1320px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-brand img {
    max-width: 200px;
  }
}
/* For large screens (1024px and above) */
@media screen and (min-width: 1024px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 15px;  /* Add padding to left */
    padding-right: 15px; /* Add padding to right */
    display: inline-block; /* Ensure links appear inline */
  }

  header .navbar-nav {
    display: flex;
    justify-content: center; /* Centers the nav items */
    flex: 1;
  }
}

/* For smaller screens (below 1025px) */
@media screen and (max-width: 1077px) and (min-width:1025px){
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    align-items: center;
  }

  .navbar-nav .nav-item {
    margin: 0 10px;
  }
}
@media screen and (max-width: 1045px) and (min-width: 1025px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    align-items: center;
  }

  .navbar-nav .nav-item {
    margin: 0 10px;
  }
}


/* @media screen and (min-width: 1024px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0px;
  }
  header .navbar-nav {
    display: flex;
    justify-content: center;
    flex: 1;
  }
} */
@media screen and (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}

figure {
  padding: 40px 0;
}
figure h1 {
  font-weight: 700;
  margin-bottom: 0;
}
.bannerContent h2,
.bannerContent p {
  margin-bottom: 30px;
}

.section-widget {
  padding-top: 40px;
  padding-bottom: 40px;
}
.primary-col {
  background-color: var(--primary);
}
.color-white {
  color: var(--white);
}
.cols-widget {
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--white);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}
.cols-widget::after {
  position: absolute;
  height: 100%;
  width: 1px;
  left: 0;
  right: 0;
  content: " ";
  top: 0;
}
.cols-widget + .cols-widget::after {
  background-color: rgba(255, 255, 255, 0.6);
}
.cols-widget h4 {
  font-weight: 700;
}
.cols-widget p {
  font-weight: 400;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .cols-widget::after {
    height: 1px;
    width: 100%;
  }
  .section-widget {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.content-rows {
  padding-top: 35px;
  padding-bottom: 35px;
}

.benefits-col i {
  width: 80px;
  flex: 0 0 auto;
  margin-right: 30px;
  max-width: 100%;
}
.form-group {
  margin-bottom: 15px;
}
.Benefits .form-group:last-child,
.Benefits .form-group:last-child p {
  margin-bottom: 0;
}
.getInTouch {
  background-color: #f9fafb;
  padding: 30px;
  border-radius: 10px;
}
.getInTouch p {
  color: #666666;
}
@media screen and (max-width: 767px) {
  .content-rows + .content-rows {
    border-top: 1px rgba(0, 0, 0, 0.2) solid;
  }
}

@media screen and (max-width: 1200px) {
  .benefits-col i {
    width: 60px;
  }
}

footer.section-widget {
  background-color: #2b3131;
  color: #ffffff;
  padding-bottom: 20px;
}
.footerLinks,
.socialmedia {
  list-style: none;
  padding: 0 0 20px;
  margin: 0 0 20px;
}
footer a {
  color: var(--white);
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}
footer a:hover {
  color: var(--primary);
}
.bottom {
  /* border-top: 1px rgba(255, 255, 255, 0.2) solid;  */
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  padding-top: 20px;
}
.socialmedia {
  margin-left: -15px;
  margin-right: -15px;
}
.socialmedia > li {
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 992px) {
  .colLinks {
    flex-wrap: wrap;
  }
  .colLinks > li {
    flex: 0 0 50%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  footer.section-widget {
    text-align: center;
  }
  .footerLinks {
    border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
  }
}
.justify-text {
  display: flex;
  justify-content: space-between;
  text-align: justify;
}
.border1 {
  margin-top: 100px;
}
.navbar-nav .nav-link.active {
  color: #1e90ff !important;
}
.font {
  font-size: 16px;
}
.back-to-top {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #0d6efd !important;
  color: white !important;
}

.videoframe {
  width: 100%;
  height: 70%;
}

.custom-btn {
  min-width: 50px !important;
  text-align: center;
}
.signInform {
  box-shadow: 0 2px 4px rgb(34 34 34 / 14%);
  padding: 20px 30px;
  background-color: white;
}

.modal-body {
  padding: 25px;
}
.Close_icon {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 10px;
  text-decoration: none;
  top: -1px;
  z-index: 1;
}
.modal-content {
  background-color: #55535385 !important;
  border: none;
  max-width: 100%;
  outline: none;
  position: relative;
}
.modal-dialog {
  align-items: center;
  display: flex;
  height: 600px !important;
  justify-content: center;
  margin: 0;
  max-width: 1000px !important;
  width: 100vw;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-wrapper {
  position: relative;
  border: 20px solid #55535385;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

.videoframe {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.videoframe:poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.play-pause-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}

.video_icon1 {
  color: white;
  font-size: 5rem;
}
.video_icon {
  color: white;
  font-size: 5rem;
  /* animation: grow 1s infinite; */
}
/* @keyframes grow {
  from {
    box-shadow: 0px 0px 1em #327cec;
  }
  to {
    box-shadow: 0px 0px 3em #4082e4;
  }
} */
/* .video_icon::before{
  color: black;
} */
.contact-icon {
  margin-bottom: auto;
  margin-right: 1.2rem;
}
.ContactLink {
  font-size: 20px;
  list-style: none;
}
.main-btn {
  height: 50px;
}

/* quill css */

.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}

.ql-font-monospace {
  font-family: "Courier New", Courier, monospace;
  white-space: pre-wrap;
}

.ql-font-serif {
  font-family: "Times New Roman", Times, serif;
}
/* 
.ql-size-large {
  font-size: 1.5em; 
}

.ql-size-huge {
  font-size: 2.5rem;
  line-height: 1.5;  
}
.ql-size-small {
  font-size: 0.75em;
} */
.custom-toast .Toastify__toast-body {
  font-size: 14px !important;
  z-index: 9999 !important;
}

.navbar-nav .nav-link.active-blue {
  color: var(--primary) !important; 
}

.navbar-nav .nav-link.active-link {
  color: var(--pink) !important;
}

.navbar-nav .nav-link.active-default {
  color: var(--primary) !important;
}

.navbar-nav .nav-link.active-success {
  color: var(--success) !important; 
}
/* heading color */
.heading-color-student {
  color: #c39e95; 
}

.heading-color-corporate {
  color: #1e90ff;
}

.heading-color-conference {
  color: #687d6c;
}

.heading-color-default {
  color: #333; 
}


/* hover 3 section */

.hover-effect {
  transition: background-color 0.3s ease-in-out;
}

.hover-effect:hover {
  background-color: rgba(0, 0, 0, 0.1); 
}

.hover-effect.primary-col:hover {
  background-color: #0C5ED7; 
}
.conf-back {
  background-color: #687D6c;
}
.hover-effect.conf-back:hover {
  background-color: #5f8867; 
}
.student-col{
  background-color: #C39E95;
}
.hover-effect.student-col:hover {
  background-color: #d8beb7; 
}

/* know more */



.know-more-btn {
  color: white;
  border: none;
  border-bottom: 1px solid white;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  align-self: center; 
  margin-top: auto; 
}

.know-more-btn:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.custom-toast .Toastify__toast-body {
  font-size: 14px !important;  
}
.Toastify__toast-theme--light{
  font-size: 14px !important;  
}