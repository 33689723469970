@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --success: #687D6c;
    --white: #ffffff;
    --interfont: "Inter", sans-serif;
    --poppinfont: "Poppins", sans-serif;
}
/* light pastal  #edffd3 */
.heading-col{color: var(--success);}
/* 3 cols */


.navbar-nav .nav-link {
    color: #000; 
    font-weight: 500;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .navbar-nav .nav-link:hover {
    color: #198754;
  }
  
  .navbar-nav .nav-link.active-success {
    color: #198754 !important; 
  }


  .footer-con a {
    color: var(--white);
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .footer-con a:hover {
    color:#198754;
  }
  /* image size */
 