.css-w614i4 {
  position: relative;
  text-align: left;
  opacity: 0.7;
  background: transparent;
  color: #7b809a;
  border-radius: none;
  box-shadow: none;
  font-size: 13px !important;
  font-weight: 700;
  text-transform: uppercase;
}
.deleteicon {
  color: red !important;
}
