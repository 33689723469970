
.answer-group.active {
  /* Define styles for the active state */
  border: 2px solid red;
}
.item-group {
  border: 1px solid #49a3f1;
}
.item-group.active {
  /* Define styles for the active state */
  border: 2px solid red;
}

.css-117w1su-MuiStepIcon-text {
  fill: red !important;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.css-valp68:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-1hc8uz3:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
  .filedset_border{
    border:1px solid rgba(0, 0, 0, 0.11) !important;
   border-radius: 10px;
  }