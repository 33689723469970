text {
    font-size: 14px !important;
  
    @media (max-width: 1280px) {
      font-size: 14px !important;
    }
  
    @media (max-width: 1024px) {
      font-size: 10px !important;
    }
  
    @media (max-width: 768px) {
      font-size: 10px !important;
    }
  
    @media (max-width: 600px) {
      font-size: 10px !important;
    }
  
  }
  .filedset_border{
    border:1px solid rgba(0, 0, 0, 0.11) !important;
   border-radius: 10px;
  }