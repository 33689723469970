/* editorStyles.css */
.react-quill-container {
    border-radius: 8px;
    border: 1px solid rgb(224, 233, 242);
    position: relative;
    z-index: 999;
    height: auto;
  }
  
  .react-quill-container .ql-editor {
    border-radius: 8px;
    font-size: 14px;
    min-height: 18em;
    max-height: 18em;
    overflow-y: auto;
  }
/*   
  .react-quill-container .ql-toolbar {
    border-radius: 8px;
    border-bottom: none;
  } */
  
  .content-data p {
    padding: 0px;
    margin: 0px;
  }