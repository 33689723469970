.css-w614i4 {
  position: relative;
  text-align: left;
  opacity: 0.7;
  background: transparent;
  color: #7b809a;
  border-radius: none;
  box-shadow: none;
  font-size: 17px !important;
  font-weight: 700;
  text-transform: uppercase;
}
.css-1as1wpd-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.011em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.75rem 3rem !important;
  border-bottom: 0.0625rem solid #f0f2f5;
}
.css-w614i4 {
  position: relative;
  text-align: left;
  opacity: 0.7;
  background: transparent;
  color: #7b809a;
  border-radius: none;
  box-shadow: none;
  font-size: 17px !important;
  font-weight: 700;
  text-transform: uppercase;
}
.deleteicon {
  color: red !important;
}
