@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --pink: #c39e95;
  --white: #ffffff;
  --interfont: "Inter", sans-serif;
  --poppinfont: "Poppins", sans-serif;
}
/* light pastal  #edffd3 */
.heading-color {
  color: var(--pink);
}
/* 3 cols */
/* fde8e3 */
.btn-Pink {
  background-color: #755952;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 45px;
  min-width: 145px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.btn-outline-pink {
  border: 2px solid var(--pink);
  color: var(--pink);
  background-color: transparent;
  padding: 6px 12px;
  font-size: 15px;
  font-weight: 800;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-Pink:disabled {
  background-color: #d4c3c3;
  cursor: not-allowed;
}

.btn-outline-pink:hover {
  background-color: var(--pink);
  color: var(--white);
}

.btn-Pink:hover {
  background-color: #d4b1a8;
  color: #fff;
}

.navbar-nav .nav-link.active-link {
  color: var(--pink) !important;
}

.section-widget {
  padding-top: 40px;
  padding-bottom: 40px;
}
.primary-col {
  background-color: var(--primary);
}
.color-white {
  color: var(--white);
}
.cols-widget {
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--white);
  position: relative;
}
.cols-widget::after {
  position: absolute;
  height: 100%;
  width: 1px;
  left: 0;
  right: 0;
  content: " ";
  top: 0;
}
.footer-student a {
  color: var(--white);
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer-student a:hover {
  color: var(--pink);
}
