.css-w614i4 {
  position: relative;
  text-align: left;
  opacity: 0.7;
  background: transparent;
  color: #7b809a;
  border-radius: none;
  box-shadow: none;
  font-size: 17px !important;
  font-weight: 700;
  text-transform: uppercase;
}
.css-1as1wpd-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.011em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.75rem 3rem !important;
  border-bottom: 0.0625rem solid #f0f2f5;
}

.css-qtdsus-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: green !important;
}
.css-117w1su-MuiStepIcon-text {
  fill: #fff !important;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.css-qtdsus-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: green !important;
}
.css-117w1su-MuiStepIcon-text {
  fill: #fff !important;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.css-6msobk-MuiButtonBase-root-MuiButton-root :hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
}
.css-6msobk-MuiButtonBase-root-MuiButton-root:hover {
  opacity: 1.75;
  background-color: transparent;
  border: 1px solid #1a73e8 !important;
}
.css-qtdsus-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: green !important;
}

.css-19vaau7.Mui-active  {
  color: #1a73e8 !important;
}
.css-106uxcw-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-19vaau7.Mui-completed {
  color: white !important;
}

.css-1hc8uz3:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}

.css-6msobk-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}

.css-1972oc1-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
  color: white !important;
}
.css-1irgnpm-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  color: white !important;
  border: 1px solid #1a73e8 !important;
}
.css-17mnlci-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  color: white !important;
  border: 1px solid #1a73e8 !important;
}

.css-v4n1ri-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}

.css-1epxvkz:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-valp68:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-mpj1yy:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid rgb(26, 115, 232) !important;
}
.css-1hu0klz-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}
.css-lhgfej:hover {
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
}

.css-qtdsus-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: black !important;
}

.css-zyfrhq-MuiStepLabel-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.01071em;
  display: block;
  -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-top: 0.5rem !important;
  font-weight: 500 !important;
  font-size: 0.95rem !important;
  color: black !important;
  text-transform: uppercase;
}

.css-15vpzwh .MuiAutocomplete-tag {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: auto;
  padding: 0.25rem;
  background-color: rgb(26, 115, 232) !important;
  color: rgb(255, 255, 255);
}
/* edit model in user interst */
.css-1dur2uz-MuiPaper-root-MuiDialog-paper {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: none !important;
  max-width: none !important;
  width: 450px !important;
  border-radius: 0.5rem;
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
}

.css-xicbw2-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-1dta8ot-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid #1a73e8 !important;
  color: white !important;
}
.css-xicbw2-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(233, 30, 99, 0.04);
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-zsdzi5-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-2a5w94-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-w13ava-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-537krz:hover {
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-d6m3rs:hover {
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-1qj0phy:hover {
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-mms94u:hover {
  text-decoration: none;
  border: 1px solid rgb(26, 115, 232) !important;
  color: white !important;
}
.css-117w1su-MuiStepIcon-text {
  fill: white !important;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.css-7x8tle-MuiStepConnector-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: absolute;
  top: 12px;
  left: calc(-50% + 15px);
  right: calc(50% + 10px);
  color: black !important;
  background: black !important;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  top: 14%;
  left: -50%;
  right: 50%;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:focus {
  outline: none;
}
.MuiAutocomplete-input:focus {
  box-shadow: none !important ;
  padding: 0;
}

.MuiAutocomplete-input {
  padding: 0 !important;
}
